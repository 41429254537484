import { words } from './words.js';

/**
 * Converts `string` to
 * [kebab case](https://en.wikipedia.org/wiki/Letter_case#Special_case_styles).
 *
 * @since 3.0.0
 * @category String
 * @param {string} [string=''] The string to convert.
 * @returns {string} Returns the kebab cased string.
 * @see camelCase, lowerCase, snakeCase, startCase, upperCase, upperFirst
 * @example
 *
 * kebabCase('Foo Bar')
 * // => 'foo-bar'
 *
 * kebabCase('fooBar')
 * // => 'foo-bar'
 *
 * kebabCase('__FOO_BAR__')
 * // => 'foo-bar'
 */
var kebabCase = function (str) {
  if (str === void 0) {
    str = '';
  }
  return words(str.replace(/['\u2019]/g, '')).reduce(function (result, word, index) {
    return result + (index ? '-' : '') + word.toLowerCase();
  }, '');
};

export { kebabCase };
