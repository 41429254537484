import { COOKIES_CONFIG } from '@belong/common';
import { destroyCookie } from 'nookies';
import { UserClaimModel } from 'src/features/users/useUser';
import { apiClient, clearJwtToken } from 'src/utils/api-client';
import type { ApiClient } from 'src/utils/api-client';
import { setEnhancedConvertionData } from 'src/utils/tracking';
import {
  ActionTodoViewModel,
  AuthToken,
  ExternalLoginModel,
  ForgotPasswordModel,
  LoginUserModel,
  RegisterUserModel,
  ResetPasswordModel,
  UpdateUserBasicInfoModel,
  UserBasicInfoModel,
  UserModel,
} from './models';

const { JWT_TOKEN, REFRESH_TOKEN } = COOKIES_CONFIG;

export function loginService(user: LoginUserModel) {
  return apiClient.post<AuthToken>('/users/login', user);
}

export function loginExternalService(user: ExternalLoginModel) {
  return apiClient.post<AuthToken>('/users/login-external', user);
}

export function logoutService() {
  return apiClient.post<void>('/users/logout');
}

export function forgotPasswordService(forgotPasswordEmail: ForgotPasswordModel) {
  return apiClient.post<void>('/users/forgot-password', forgotPasswordEmail);
}

export function resetPasswordService(resetPasswordData: ResetPasswordModel) {
  return apiClient.post<void>('/users/reset-password', resetPasswordData);
}

export function setPasswordService(setPasswordData: ResetPasswordModel) {
  return apiClient.post<void>('/users/set-password', setPasswordData);
}

type Register = {
  userId?: string;
  token?: string;
  expiration?: string;
  refreshToken?: string;
};

export function registerService(registerData: RegisterUserModel) {
  return apiClient.post<Register>('/users/register', registerData);
}

export async function fetchUserService(client: ApiClient = apiClient) {
  try {
    const user = await client.get<UserModel>('/users');

    if (typeof window !== 'undefined' && user?.userId) {
      window.mixpanel?.identify?.(user.userId);
      window.mixpanel?.register?.({
        email: user.email,
      });

      window.FS?.identify?.(user.userId, {
        email: user.email,
      });
    }

    return user;
  } catch (e) {
    clearJwtToken();
    destroyCookie(null, REFRESH_TOKEN.name);
    destroyCookie(null, JWT_TOKEN.name);

    throw e;
  }
}

export function fetchUserClaimsService(client: ApiClient = apiClient) {
  return client.get<UserClaimModel>('/users/claims');
}

export async function fetchUserProfileService(client: ApiClient = apiClient) {
  const profile = await client.get<UserBasicInfoModel>('/users/profile');
  if (typeof window !== 'undefined') {
    setEnhancedConvertionData(profile);
  }

  return profile;
}

export function updateUserProfileService(userProfile: UpdateUserBasicInfoModel) {
  return apiClient.put<UserBasicInfoModel>('/users/profile', userProfile);
}

export function fetchUserProfileByIdService(userId: string, client: ApiClient = apiClient) {
  return client.get<UserBasicInfoModel>(`/users/profile/${userId}`);
}

export function fetchUserToDosService(client: ApiClient = apiClient) {
  return client.get<ActionTodoViewModel[]>('/users/todo');
}

export function fetchUserLoginStatus(uniqueId: string, client: ApiClient = apiClient) {
  return client.get<any>(`/users/login-status/${uniqueId}`);
}
