import React from 'react';
import { Image, LinkBase, Text } from '@belong/ui';
import clsx from 'clsx';
import styles from './new-market-banner.module.css';

export function NewMarketBanner() {
  return (
    <div className={styles.banner}>
      <div className={clsx('flex justify-between z-50 w-full', styles.banner)}>
        <div className="flex md:flex-1 md:justify-end overflow-hidden md:mr-2xl absolute md:relative left-0 bottom-0 h-full">
          <Image
            className="max-w-none w-auto md:hidden h-full"
            src="/banners/v3/lp_nw_banner_left_m.svg"
            alt="Utah mountain"
            aria-hidden="true"
          />
          <Image
            className="hidden md:block w-auto max-w-none"
            src="/banners/v3/lp_nw_banner_left.svg"
            alt="Utah mountain"
            aria-hidden="true"
          />
        </div>
        <LinkBase
          href="/blog/belong-expands-new-markets"
          className={clsx(
            'flex flex-col md:flex-1 xl:flex-row items-center justify-center text-center leading-p1 gap-y-2xs md:whitespace-nowrap z-10',
            styles.textContainer
          )}
        >
          <Text className="text-white mr-2xs leading-p1 px-2xl md:px-0 font-semibold" as="h4">
            Belong expands to new markets nationwide
          </Text>

          <Text
            fontWeight="semibold"
            className="text-green leading-p1 pl-xs hover:text-light-green"
            style={{ textShadow: '2px 0px 10px #3EE492CC;', transition: 'all 0.1s ease-in-out' }}
          >
            LEARN MORE
          </Text>
        </LinkBase>
        <div className="flex md:flex-1 items-end md:justify-start overflow-hidden md:ml-2xl absolute md:relative right-0 bottom-0 h-full">
          <Image
            className="max-w-none w-auto md:hidden h-full"
            src="/banners/v3/lp_nw_banner_right_m.svg"
            alt="Utah mountain"
            aria-hidden="true"
          />
          <Image
            className="hidden md:block w-auto max-w-none"
            src="/banners/v3/lp_nw_banner_right.svg"
            alt="Utah mountain"
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
  );
}
