import { ReactNode, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { BREAKPOINTS_WIDTHS, kebabCase, useIsClientReady, useWindowSize } from '@belong/common';
import { IconMenu, IconClose } from '@belong/icons';
import { ButtonBase, Image, LinkBase } from '@belong/ui';
import clsx from 'clsx';
import { NewMarketBanner } from 'src/components/header/new-market-banner/new-market-banner';
import { useRegions } from 'src/features/app';
import { useModal } from 'src/hooks/useModal';
import type { DesktopNavigationProps } from './desktop-navigation/desktop-navigation';
import css from './header.module.css';
import { MobileNavigationProps } from './mobile-navigation/mobile-navigation';

export type HeaderVariant = 'primary' | 'secondary' | 'tertiary';

type HeaderProps = {
  regionSelector?: ReactNode;
  variant?: HeaderVariant;
  isFullWidth?: boolean;
};

export function Header({
  regionSelector: RegionSelector,
  variant = 'primary',
  isFullWidth = false,
}: HeaderProps): JSX.Element {
  const [isOverlayOpen, openOverlayModal, closeOverlayModal] = useModal();
  const [isMobileNavLoaded, setIsMobileNavLoaded] = useState(false);
  const { width } = useWindowSize();
  const { pathname, asPath } = useRouter();
  const isClientReady = useIsClientReady();
  const { regions } = useRegions();

  const isMobile = width < BREAKPOINTS_WIDTHS.LG;
  const bannerUrls = [
    '/',
    '/homeowners',
    '/homeowners/leasing',
    ...regions.map((region) => {
      return `/homeowners/${kebabCase(region.name)}`;
    }),
  ];

  function handleOverlayOpen() {
    // After mobile nav is opened for the first time, rely only on the isOverlayOpen prop for opening and closing.
    if (!isMobileNavLoaded) {
      setIsMobileNavLoaded(true);
    }

    openOverlayModal();
  }

  return (
    <>
      {(bannerUrls.includes(pathname) || bannerUrls.includes(asPath)) && !isOverlayOpen && <NewMarketBanner />}
      <header className={clsx(variantToBgClassNameMap[variant], 'h-header shrink-0 relative')}>
        <div
          className={clsx('container h-full flex items-center justify-between relative', {
            ['w-full px-lg']: isFullWidth,
          })}
        >
          {(!isMobile || !isOverlayOpen) && (
            <div className="flex items-center">
              <div className="flex mr-lg md:mr-xl">
                <LinkBase href="/" className={css.logoLink} aria-label="Go To Homepage">
                  <Image src={variantToLogoSrcMap[variant]} alt="belong logo" aria-hidden="true" />
                </LinkBase>
              </div>
              {RegionSelector}
            </div>
          )}

          {isClientReady && (
            <div className="flex ml-auto">
              {isMobile ? (
                <div className="flex lg:hidden z-20 items-end">
                  {isOverlayOpen ? (
                    <ButtonBase onClick={closeOverlayModal}>
                      <IconClose width={16} className={variantToClassNameMap[variant]} />
                    </ButtonBase>
                  ) : (
                    <ButtonBase onClick={handleOverlayOpen} aria-label="Burger Menu">
                      <IconMenu width={24} height={18} aria-hidden="true" className={variantToClassNameMap[variant]} />
                    </ButtonBase>
                  )}
                  {(isOverlayOpen || isMobileNavLoaded) && (
                    <MobileNavigation
                      isOverlayOpen={isOverlayOpen}
                      openOverlayModal={openOverlayModal}
                      closeOverlayModal={closeOverlayModal}
                    />
                  )}
                </div>
              ) : (
                <div className="hidden lg:flex">
                  <DesktopNavigation variant={variant} />
                </div>
              )}
            </div>
          )}
        </div>
      </header>
    </>
  );
}

const DesktopNavigation = dynamic<DesktopNavigationProps>(
  () => import('./desktop-navigation/desktop-navigation').then((mod) => mod.DesktopNavigation),
  { ssr: false }
);

const MobileNavigation = dynamic<MobileNavigationProps>(
  () => import('./mobile-navigation/mobile-navigation').then((mod) => mod.MobileNavigation),
  { ssr: false }
);

const variantToClassNameMap: Record<HeaderVariant, string> = {
  primary: '',
  secondary: 'text-white',
  tertiary: '',
};

const variantToBgClassNameMap: Record<HeaderVariant, string> = {
  primary: 'bg-white',
  secondary: 'bg-transparent',
  tertiary: 'bg-transparent',
};

const variantToLogoSrcMap: Record<HeaderVariant, string> = {
  primary: '/logos/belong_logo_web.svg',
  secondary: '/logos/belong_logo_web_white.svg',
  tertiary: '/logos/belong_logo_web.svg',
};
